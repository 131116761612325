import React from "react"
import { useNavigate } from "react-router-dom"
import { FloatButton } from "antd"
import { HomeOutlined } from '@ant-design/icons'

const HomeButton = () => {
    const navigate = useNavigate()

    return (
        <FloatButton
            onClick={() => navigate("/monitor-tools")}
            shape="circle"
            type="primary"
            style={{
                top: 12,
                left: 74,
            }}
            icon={<HomeOutlined />}
        />
    )
}


export default HomeButton