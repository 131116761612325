export const isScreenLGAndBigger = () => {
    return window.outerWidth > 1240
}

export const mapLocationToMenuKey = () => {
    return window.location.pathname.replace("/", "")
}

export const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer")
}

export const hasBadCharachters = (text) => {
    if (!text) return

    try {
        new RegExp(text)
        return false
    } catch (error) {
        return true
    }
}

export const isArrayBuffer = (value) => {
    return (
        value instanceof ArrayBuffer ||
        Object.prototype.toString.call(value) === "[object ArrayBuffer]"
    )
}

export const getKeyFromObject = (object, key) => {
    if (object == null) return null
    if (object.hasOwnProperty(key)) {
        return object[key]
    }

    return null
}
