import { Button } from "antd"
import React, { PureComponent } from "react"
import { openInNewTab } from "../utils/utils"
import { questionnaireUrl } from "../config"
import { StarOutlined } from '@ant-design/icons'
import { rateButtonColor } from "../colors"

class RateButton extends PureComponent {

    render() {
        return (
            <Button
                style={{ background: rateButtonColor, color: 'white' }}
                icon={<StarOutlined />}
                onClick={() => openInNewTab(questionnaireUrl)}>
                Rate Monitoring Tools
            </Button>
        )
    }
}

export default RateButton