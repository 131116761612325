const isDevelopment = process.env.NODE_ENV === "development"

export const API_URL = isDevelopment
    ? "http://localhost:8000"
    : process.env.REACT_APP_API_URL

export const MAIN_SITE_URL = isDevelopment
    ? "https://life-genera.eu"
    : process.env.REACT_APP_MAIN_SITE_URL

export const questionnaireUrl = "https://docs.google.com/forms/d/e/1FAIpQLSejT0T5-_vzzAssWehCdiRwKeAuhfF5CgHLexPZczNYtDTNWg/viewform"

import generaLogo from '../public/logo.png'
export const LOGO_FILE = generaLogo

import imageNumberOne from "../public/img1.jpg"
export const IMAGE_NUMBER_ONE = imageNumberOne

import imageNumberTwo from "../public/img2.jpg"
export const IMAGE_NUMBER_TWO = imageNumberTwo

import imageNumberThree from "../public/img3.jpg"
export const IMAGE_NUMBER_THREE = imageNumberThree

import imageNumberFour from "../public/img4.jpg"
export const IMAGE_NUMBER_FOUR = imageNumberFour
